<template>
  <div class="layout-breadcrumbs">
    <div class="container">
      <div class="layout-breadcrumbs-content">
        <ul class="breadcrumbs">
          <template v-for="(match,index) in matchedPath">
            <li class="breadcrumbs-list" @click="toPath(match)">{{ $i18n.t(match.meta.title) }}</li>
            <li class="breadcrumbs-list" v-if="index<matchedPath.length-1">></li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  computed: {
    matchedPath() {
      return this.$route.matched.map(i => {
        if(i.regex.test(this.$route.path) && this.title){
          i.meta.title = this.title
          //自定义的title不让跳转
          i.meta.click = false
        }
        let parentPath = i.meta.parent;
        if (parentPath) {
          return [this.$router.resolve(parentPath).route, i];
        }
        return i;
      }).flat(2);
    },
  },
  mounted() {

  },
  methods: {
    toPath(route) {
      if (route.path === this.$route.path || route.meta.click==false) {
        return;
      }
      this.$router.push({
        path: route.path || '/',
      });
    },
  },
};
</script>

<style scoped lang="less">
.layout-breadcrumbs {
  border-top: 1px solid rgb(244, 248, 249);
}

.layout-breadcrumbs-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
  flex-wrap: wrap;
  border-bottom: 1px solid rgb(244, 248, 249);
  h3 {
    color: rgb(0, 0, 0);
    margin: 10px 0 6px 0;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 1px;
  }

  h4 {
    color: rgb(127 140 151);
    margin: 5px 0 5px 0;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
  }

  .breadcrumbs {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 2px;

    .breadcrumbs-list {
      list-style: none;
      color: rgb(127, 140, 151);
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      padding: 8px 4px;
      transition: .2s ease;

      &:hover {
        color: var(--themeColor);
      }
    }
  }
}

@media (min-width: 1200px){
  // .container {
  //   width: 1400px;
  // }
}

@media (max-width: 991px) {
  .layout-breadcrumbs-content {
    padding: 15px 0;
  }
}
</style>
