import axiosApi from '@/api/axiosApi';

//查询所有新闻
export const listAll = (data) => axiosApi({
    method: 'POST',
    url: '/intserv/news/listAll',
    data,
});

//新闻详情
export const detail = (data) => axiosApi({
    method: 'GET',
    url: '/intserv/news/detail',
    data,
});

// 分页查询
export const pageWith = (data) => axiosApi({
    method: 'POST',
    url: '/intserv/news/pageWith',
    data
})
